<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('service.forms.overview')" class="service forms catalog">
    <CCol cols="12" lg="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              {{$t('forms.Forms')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch class="m-0"
                          v-model="showInactive"
                          size="is-small"
                          @input="filterFormsData()">
                  {{ $t('common.show_inactive') }}
                </b-switch>
              </div>
            </CCol>           
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('common.Categories')}}</b> 
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('service.forms.addcategory')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="openSidebarRight('form_category_details', { form_category_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('common.Add_category')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !categoriesLoading}">
                    <div v-if="categoriesLoading">
                      <loadingSpinner mode="auto" v-if="categoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="categoriesTable"
                               class="data_table"
                               :data="categories"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="categoriesPaginated"
                               :per-page="categoriesPerPage"
                               :current-page.sync="categoriesPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               :row-class="(row, index) => row.form_category_id_external === activeCategory.form_category_id_external && 'active'">
                            
                            <template slot-scope="props">                      
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.category_image_id"
                                    class="item_image pointer"
                                    v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/service-form-category/' + props.row.form_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }"
                                    @click="getCategoryForms(props.row.form_category_name, props.row.form_category_id_external);">
                                </div>                               
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getCategoryForms(props.row.form_category_name, props.row.form_category_id_external);">
                                  <i class="fas fa-list"/>               
                                </div>         
                              </b-table-column>            
                              <b-table-column field="form_category_name" :label="$t('common.Category')" :searchable="searchEnabled">
                                <span class="pointer" @click="getCategoryForms(props.row.form_category_name, props.row.form_category_id_external);">                        
                                  <b>{{props.row.form_category_name}}</b>
                                </span>                               
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_forms}} {{props.row.nr_of_forms !== 1 ? $t('forms.forms') : $t('forms.form')}}                                      
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_forms}} {{props.row.nr_of_active_forms !== 1 ? $t('forms.forms') : $t('forms.form')}}
                                  </span>                                    
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                
                              </b-table-column>
                              <b-table-column field="form_category_description" :label="$t('common.Description')" :searchable="searchEnabled">
                                <span>{{props.row.form_category_description ? props.row.form_category_description : '-'}}</span>
                              </b-table-column>                              
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="service form category"
                                                    :targetGroupsIdExternal="props.row.form_category_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" width="10%" :visible="checkPermission('service.forms.editcategory')">
                                <div class="d-flex justify-content-lg-end">
                                 <CButton color="primary" 
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Category_managed_by_owner')} : ''"
                                          @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('form_category_details', { form_category_id_external: props.row.form_category_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_categories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol cols="6" lg="6" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeCategory.form_category_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCategory.form_category_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('forms.Forms')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('service.forms.add') && activeCategory.form_category_id_external && activeCategory.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="showNewForm();">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('forms.Add_form')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCategory.form_category_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !formsLoading}">
                    <div v-if="formsLoading">
                      <loadingSpinner mode="auto" v-if="formsLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="formsTable"
                               class="data_table includes_dropdown"
                               :data="forms"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="formsPaginated"
                               :per-page="formsPerPage"
                               :current-page.sync="formsPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize">
                            
                            <template slot-scope="props">
                              <b-table-column field="form_name" :label="$t('forms.Form')" :searchable="searchEnabled" width="60%">
                                <span><b>{{props.row.form_name}}</b></span>
                                <span class="d-block meta">
                                  <span>{{props.row.nr_of_submissions}} {{props.row.nr_of_submissions !== 1 ? $t('forms.submissions') : $t('forms.submission')}}</span>
                                </span>                                
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                                                
                              </b-table-column>
                              <b-table-column field="form_status" :label="$t('common.Status')" :searchable="searchEnabled" width="30%">
                                <span>{{props.row.form_status}}</span>                                                             
                              </b-table-column>                              
                              <b-table-column field="action">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown color="primary" toggler-text="" :caret="false" placement="bottom-end" class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem v-if="checkPermission('service.forms.edit')">
                                      <div class="d-flex align-items-center" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('forms.Form_managed_by_owner')} : ''"                                      
                                           @click.stop="props.row.from_marketplace === 'N' ? showFormDetails(props.row.form_id_external) : null">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('forms.Edit_form')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>                                    
                                    <CDropdownItem>
                                      <div class="d-flex align-items-center" @click="showFormSubmissions(props.row.form_id_external)">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fa-solid fa-inbox"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('forms.Show_submissions')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('forms.No_forms_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('forms.Select_category_for_forms')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>       
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'
import productVariationsPopover from '@/components/loyalty/productVariationsPopover.vue';

export default {
  name: 'Forms',
  components: {
    loadingSpinner,
    noPermission,
    targetGroupPopover,
    productVariationsPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,   
      apiBaseUrl: null,
      clientToken: null,      
      activeCategory: {
        form_category_name: null,
        form_category_id_external: null
      },
      categories: [],
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesPaginated: false,
      categoriesPerPage: 8,
      categoriesPage: 1,
      forms: [],
      formsLoaded: [],
      formsLoading: false,
      formsLoaded: false,
      formsPaginated: false,
      formsPerPage: 8,
      formsPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true,
      showInactive: false    
    }
  },
  methods: {
    filterFormsData() {
      this.getCategories();

      if(this.activeCategory.form_category_name && this.activeCategory.form_category_id_external) {
        this.getCategoryForms(this.activeCategory.form_category_name, this.activeCategory.form_category_id_external);
      }     
    },       
    getCategories() {
      // Start the loader
      if(this.categoriesLoaded === false) this.categoriesLoading = true;
      // Get the categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/forms/categories/overview')
      .then(res => {
        this.categories = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.categories = this.categories.filter( i => ['Y'].includes( i.active ) );        
        // Check if the pagination should be enabled
        (this.categories.length > this.categoriesPerPage) ? this.categoriesPaginated = true : this.categoriesPaginated = false;
        // Stop the loader
        this.categoriesLoading = false;
        // Update the categoriesLoaded value
        this.categoriesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getCategoryForms(categoryName, categoryIdExternal) {
      // Check if a different category is selected
      if(this.activeCategory.form_category_id_external !== categoryIdExternal) {
        // Start the loader
        this.formsLoading = true;      
        // Clear the forms
        this.forms = [];
        // Update the active data
        this.activeCategory = { 
          form_category_name: categoryName,
          form_category_id_external: categoryIdExternal        
        };
      }
      // Get the forms
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/forms/category/' + categoryIdExternal + '/forms')
      .then(res => {
        this.forms = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.forms = this.forms.filter( i => ['Y'].includes( i.active ) );
        // Enable the pagination if necessary
        (this.forms.length > this.formsPerPage) ? this.formsPaginated = true : this.formsPaginated = false;        
        // Stop the loader
        this.formsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showNewForm() {
      // Save the newFormCategory to localStorage
      localStorage.setItem('newFormCategory', JSON.stringify(this.activeCategory));
      // Navigate to the new form page
      this.$router.push({path: '/service/form/new'});
    },    
    showFormDetails(formIdExternal) {
      // Save the activeFormCategory item to localStorage
      localStorage.setItem('activeFormCategory', JSON.stringify(this.activeCategory));
      // Navigate to form details page
      this.$router.push({path: `/service/form/${formIdExternal.toString()}`});
    },
    showFormSubmissions(formIdExternal) {
      // Save the activeFormCategory item to localStorage
      localStorage.setItem('activeFormCategory', JSON.stringify(this.activeCategory));
      // Navigate to form submissions page
      this.$router.push({path: `/service/form/${formIdExternal.toString()}/submissions`});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getPlatformPermissions();
    this.getCategories();

    // Check if the activeFormCategory item is available in localStorage
    if(localStorage.getItem('activeFormCategory') !== null && localStorage.getItem('activeFormCategory') !== undefined) {
      // Set the activeCategory
      this.activeCategory = JSON.parse(localStorage.getItem('activeFormCategory'));
      // Get the active category forms
      this.getCategoryForms(this.activeCategory.form_category_name, this.activeCategory.form_category_id_external);
      // Remove the activeFormCategory item from localStorage
      localStorage.removeItem('activeFormCategory');
    }

    this.$bus.$on('update_form_categories', () => {
      this.getCategories();
    });  
  },
  beforeDestroy() {
    this.$bus.$off('update_form_categories');
  }  
}
</script>